import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Web3 from "web3";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  font-family: 'coder';
  padding: 10px;
  font-size: 24px;
  border-radius: 6px;
  border: none;
  background-color: #202443;
  padding: 10px;
  letter-spacing: 6px;
  // font-weight: bold;
  color: white;
  width: 450px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: #4F7DFD
  }
  @media (max-width: 580px) {
    width: 350px;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #202443;
  padding: 10px;
  // font-weight: bold;
  font-size: 50px;
  color: white;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: #4F7DFD
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  // margin: auto;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  display: inline;
  width: 160px;
  @media (max-width: 767px) {
    width: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 300px;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1000px) {
    width: 400px;
  }
  transition: width 0.5s;
`;


export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  :hover {
    color: red;
  }
`;

export const WalletBox = styled.div`
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid white;
  background-color: #202443;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :hover {
    background-color: #4F7DFD
  }
  @media (max-width: 580px) {
    margin-top: 20px;
    margin-left: 40px;
  }
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [walletAddress, setAddress] = useState("Not Connected");
  const [claimingNft, setClaimingNft] = useState(false);
  const [claimingNftRent, setClaimingNftRent] = useState(false);
  const [feedback, setFeedback] = useState(`You can mint both with CRO & $RENT! If it's your first time, you need to approve $RENT!`);
  const [amount, setamount] = useState(1);
  const [isApproved, setisApproved] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    Token_Contract: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    WL_COST: 0,
    DISPLAY_COST: 0,
    RENT_COST: 0,
    WL_Display: 0,
    GAS_LIMIT: 0,
    WEB_LINK: "",
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


  const claimNFTs = () => {
    let cost = CONFIG.DISPLAY_COST * amount;
    let tokens = Web3.utils.toWei(cost.toString(), 'ether');
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * amount);
    console.log("Cost: ", tokens);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.SYMBOL}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(amount)
      .send({
        gasLimit: totalGasLimit,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: tokens,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Ebisusbay.com to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const MintWithRent = () => {
    let cost = CONFIG.RENT_COST * amount;
    let tokens = Web3.utils.toWei(cost.toString(), 'ether');
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * amount);
    console.log("Gas limit: ", totalGasLimit);
    if (isApproved) {
      setClaimingNftRent(true);
      setFeedback(`Minting...`);
      blockchain.smartContract.methods
      .mintWithRent(amount)
      .send({
        gasLimit: totalGasLimit,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNftRent(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Ebisusbay.com to view it.`
        );
        setClaimingNftRent(false);
        dispatch(fetchData(blockchain.account));
      });
    } else {
      setFeedback(`Approving the contract...`);
      blockchain.tokenContract.methods
      .approve(CONFIG.CONTRACT_ADDRESS, tokens)
      .send({
        to: CONFIG.Token_Contract,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNftRent(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Contract Approved.`
        );
        setClaimingNftRent(true);
        blockchain.smartContract.methods
        .mintWithRent(amount)
        .send({
          gasLimit: totalGasLimit,
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNftRent(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Ebisusbay.com to view it.`
          );
          setClaimingNftRent(false);
          dispatch(fetchData(blockchain.account));
        });
      });
    }
  };
  


  const decrementamount = () => {
    let newamount = amount - 1;
    if (newamount < 1) {
      newamount = 1;
    }
    setamount(newamount);
  };

  const incrementamount = () => {
    let newamount = amount + 1;
    if (newamount > 10) {
      newamount = 10;
    }
    setamount(newamount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setAddress(blockchain.account.substring(0,4) + "..." + blockchain.account.substring(38,42));
      blockchain.tokenContract.methods
      .allowance(blockchain.account, CONFIG.CONTRACT_ADDRESS)
      .call({
        from: blockchain.account,
      })
      .then((receipt) => {
        console.log("Allowance:", receipt)
        console.log("isApproved?", receipt > 0)
        if(receipt > 0) {
          setisApproved(true);
        }
      });
  };
   };



  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        // ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <ResponsiveWrapperHeader>
          <a href="https://cro.homes/">
            <StyledLogo alt={"logo"} src={"/config/images/logo192.png"} />
          </a>
          <StyledLink style={{ marginTop: 30, textDecoration: "none" }} href="https://cro.homes/">
            HOME
          </StyledLink>
          <StyledLink style={{ marginTop: 30, textDecoration: "none" }} href="https://cro.homes/#roadmap">
           ROADMAP
          </StyledLink>
          <StyledLink style={{ marginTop: 30, textDecoration: "none" }} href="https://cro.homes/#rent">
            $RENT
          </StyledLink>

          <WalletBox>
            {blockchain.account !== "" ? (
            <>
            <s.TextSubTitle style={{color: "white"}}>
              {walletAddress}
              </s.TextSubTitle>
            </>
            ) : null }
          </WalletBox>
        </ResponsiveWrapperHeader>


        <s.Container flex={1} jc={"center"} ai={"center"}>
        <StyledImg alt={"logo"} src={"/config/images/img.gif"} />
        </s.Container>

        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
            
            <s.Container flex={1} jc={"center"} ai={"center"} >
            <s.StyledHR></s.StyledHR>
            <s.SpacerSmall />

           {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextSub
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder" }}
                >
                  The sale has ended.
                </s.TextSub>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextSub
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder"  }}
                  id="price"
                >
                  Price&emsp;&emsp;&emsp;&emsp;&emsp;{CONFIG.DISPLAY_COST}{" "}{CONFIG.NETWORK.SYMBOL}
                </s.TextSub>
                <s.TextSub
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder"  }}
                  id="price"
                >
                  OR
                </s.TextSub>
                <s.TextSub
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder"  }}
                  id="price"
                >
                  Price&emsp;&emsp;&emsp;&emsp;&emsp;{CONFIG.RENT_COST}{" "}$RENT
                </s.TextSub>
                <s.TextSub
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder"  }}
                >
                  {data.totalSupply} | {CONFIG.MAX_SUPPLY}
                </s.TextSub>
                <s.SpacerSmall />
                <s.StyledHR></s.StyledHR>
                <s.SpacerXSmall />
                <s.SpacerLarge />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
    
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT WALLET
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                            fontFamily: "coder",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementamount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.TextAm
                        style={{
                          fontFamily : "coder",
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        &ensp;&ensp;&ensp;&ensp;{amount}&ensp;&ensp;&ensp;&ensp;
                      </s.TextAm>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementamount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.StyledHR ></s.StyledHR>
                    <s.SpacerSmall />
                    <s.TextSub
                      style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder"  }}
                    >
                      Total&emsp;&emsp;&emsp;&emsp;&emsp;{CONFIG.DISPLAY_COST * amount}{" "}{CONFIG.NETWORK.SYMBOL}
                    </s.TextSub>
                    <s.TextSub
                      style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder"  }}
                    >
                      OR
                    </s.TextSub>
                    <s.TextSub
                      style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder"  }}
                    >
                      Total&emsp;&emsp;&emsp;&emsp;&emsp;{CONFIG.RENT_COST * amount}{" "}$RENT
                    </s.TextSub>
                    <s.SpacerSmall />
                    <s.TextSub
                      style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder"  }}
                    >
                      {feedback}
                    </s.TextSub>
                    <s.SpacerXSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"column"} style={{gap: 20}}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "MINTING..." : "MINT WITH CRO"}
                      </StyledButton>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          MintWithRent();
                          getData();
                        }}
                      >
                        {claimingNftRent ? "MINTING..." : "MINT WITH $RENT"}
                      </StyledButton>
                      <s.SpacerSmall/>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
            </s.Container>
          <s.SpacerLarge />
        </ResponsiveWrapper>
        <s.SpacerMedium />
      </s.Container>
    </s.Screen>
  );
}

export default App;
